<template>
  <div id="closingCosts">
    <blog-template
      :title="title"
      :introduction="introduction"
      :conclusion="conclusion"
      :image="image"
      :items="items"
    />
  </div>
</template>

<script>
export default {
  name: "ClosingCosts",

  components: {
    BlogTemplate: () => import("@/components/blogs/BlogTemplate"),
  },

  data: () => ({
    title: "Closing Costs",
    introduction:
      "Closing costs are the fees and expenses that buyers must pay on top of the purchase price of their home. These costs can vary significantly based on factors such as the property's location, the type of home being purchased, and the specifics of the transaction.",
    conclusion:
      "As a general rule of thumb, it's wise to budget between 1.5% to 4% of the purchase price of the home for closing costs. For example, on a $500,000 home, you should budget between $7,500 to $20,000 for closing costs. Understanding and budgeting for closing costs is a vital part of the home buying process in Ontario. By familiarizing yourself with these expenses ahead of time, you can ensure a smoother transaction and avoid any unexpected financial strain. Always consult with your real estate agent, lawyer, and mortgage advisor to get a comprehensive view of what you can expect in terms of closing costs for your specific situation.",
    image: "closing-costs.JPG",
    items: [
      {
        title: "Land Transfer Tax (LTT)",
        content:
          "In Ontario, buyers must pay a land transfer tax upon the purchase of a property. The amount is based on the purchase price of the home and can be a significant expense. First-time homebuyers may be eligible for a rebate of up to $4,000.",
      },
      {
        title: "Legal Fees and Disbursements",
        content:
          "Hiring a lawyer is essential for a property transaction in Ontario. Legal fees can vary, but they generally range from $500 to $2,500. Disbursements are additional expenses that your lawyer pays on your behalf, such as registration fees, search fees, and certificates.",
      },
      {
        title: "Title Insurance",
        content:
          "While not mandatory, title insurance is highly recommended as it protects against title defects, liens, and other issues that could affect your ownership or use of the property. The cost typically ranges from $100 to $300.",
      },
      {
        title: "Home Inspection Fee",
        content:
          "A home inspection is an important step in the buying process, providing insights into the condition of the home and identifying any potential issues. The cost for a home inspection can range from $300 to $600.",
      },
      {
        title: "Property Insurance",
        content:
          "Mortgage lenders require proof of property insurance before closing, which covers the replacement value of the structure of your home and its contents. The cost varies based on the property's value and the insurance coverage chosen.",
      },
      {
        title: "Mortgage Default Insurance",
        content:
          "If your down payment is less than 20% of the purchase price, you will need to purchase mortgage default insurance, which protects the lender in case of default. The premium can be added to your mortgage amount but expect to pay an upfront cost as well, which varies based on the purchase price and your down payment.",
      },
      {
        title: "Adjustments",
        content:
          "These are costs that the seller has prepaid, such as property taxes or utility bills, which you will need to reimburse on a pro-rated basis.",
      },
    ],
  }),
};
</script>
