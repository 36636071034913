import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Buying from '../views/Buying.vue'
import Selling from '../views/Selling.vue'
import BuyerGuide from '../views/BuyerGuide.vue'

// Tools
import Mortgage from '../views/tools/Mortgage.vue'
import LandTransferTax from '../views/tools/LandTransferTax.vue'
import Affordability from '../views/tools/Affordability.vue'
import MortgageCmhc from '../views/tools/MortgageCmhc.vue'

// Blogs
import BlogHomePage from '../views/blogs/BlogHomePage.vue'
import AvoidingPitfalls from '../views/blogs/AvoidingPitfalls.vue'
import PreparingSale from '../views/blogs/PreparingSale.vue'
import FirstHome from '../views/blogs/FirstHome.vue'
import FirstTimeBuyerMistakes from '../views/blogs/FirstTimeBuyerMistakes.vue'
import ClosingCosts from '../views/blogs/ClosingCosts.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/buying',
      name: 'buying',
      component: Buying,
    },
    {
      path: '/selling',
      name: 'selling',
      component: Selling,
    },
    {
      path: '/tools/mortgageCalculator',
      name: 'mortgageCalculator',
      component: Mortgage,
    },
    {
      path: '/tools/landTransferTax',
      name: 'landTransferTax',
      component: LandTransferTax,
    },
    {
      path: '/tools/affordability',
      name: 'affordability',
      component: Affordability,
    },
    {
      path: '/tools/mortgageCmhc',
      name: 'mortgageCmhc',
      component: MortgageCmhc,
    },
    // {
    //   path: '/buyerGuide',
    //   name: 'buyerGuide',
    //   component: BuyerGuide,
    // },
    {
      path: '/blogs',
      name: 'blogHomePage',
      component: BlogHomePage,
    },
    {
      path: '/blogs/avoiding-pitfalls',
      name: 'avoidingPitfalls',
      component: AvoidingPitfalls
    },
    {
      path: '/blogs/preparing-for-sale',
      name: 'preparingSale',
      component: PreparingSale
    },
    {
      path: '/blogs/first-home',
      name: 'firstHome',
      component: FirstHome
    },
    {
      path: '/blogs/buyer-mistakes',
      name: 'buyerMistakes',
      component: FirstTimeBuyerMistakes
    },
    {
      path: '/blogs/closing-costs',
      name: 'closingCosts',
      component: ClosingCosts
    },
  ],
})
