<template>
  <div id="home">
    <banner />

    <about />

    <testimonials />

    <home-worth />

    <subscribe />

    <social />
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      About: () => import('@/components/home/About'),
      Banner: () => import('@/components/home/Banner'),
      Social: () => import('@/components/home/Social'),
      Subscribe: () => import('@/components/home/Subscribe'),
      HomeWorth: () => import('@/components/home/HomeWorth'),
      Testimonials: () => import('@/components/home/Testimonials'),
    },
  }
</script>
