import { render, staticRenderFns } from "./Affordability.vue?vue&type=template&id=69a29a96&scoped=true"
import script from "./Affordability.vue?vue&type=script&lang=js"
export * from "./Affordability.vue?vue&type=script&lang=js"
import style0 from "./Affordability.vue?vue&type=style&index=0&id=69a29a96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69a29a96",
  null
  
)

export default component.exports