<template>
  <div id="selling">
    <buy-sell-banner
      title="Let's Sell Your Home"
      banner="selling_banner.JPG"
    />
    <selling-component />
  </div>
</template>

<script>
  export default {
    name: 'Selling',

    components: {
      SellingComponent: () => import('@/components/Selling'),
      BuySellBanner: () => import('@/components/home/BuySellBanner'),
    },
  }
</script>
