<template>
    <div id="blogHomePage">
      <blog-home-page-component />
    </div>
  </template>
  
  <script>
    export default {
      name: 'BlogHomePage',
  
      components: {
        BlogHomePageComponent: () => import('@/components/blogs/BlogHomePage'),
      },
    }
  </script>
  