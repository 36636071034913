<template>
  <div id="mortgageCmhcCalculator">
    <mortgage-cmhc-calculator />
  </div>
</template>

<script>
  export default {
    name: 'MortgageCmhc',

    components: {
      MortgageCmhcCalculator: () => import('@/components/tools/MortgageCmhcCalculator'),
    },
  }
</script>
<style scoped>
  #mortgageCmhcCalculator {
    margin-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    #mortgageCmhcCalculator {
      margin-top: 0px !important;
    }
  }
</style>
