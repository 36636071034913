import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    articles: require('@/data/articles.json'),
    drawer: false,
    items: [
      {
        text: 'Home',
        href: '/#!',
      },
      // {
      //   text: 'About',
      //   href: '#about-me',
      // },
      {
        text: 'Buying',
        href: '/buying',
      },
      {
        text: 'Selling',
        href: '/selling',
      },
      {
        text: 'Blogs',
        href: '/blogs',
      },
      {
        text: 'Tools',
        href: '',
        children: [
          {        
            text: 'Mortgage Calculator',
            href: '/tools/mortgageCalculator',
          },
          {        
            text: 'Land Transfer Tax',
            href: '/tools/landTransferTax',
          },
          {        
            text: 'Affordability Calculator',
            href: '/tools/affordability',
          },
          {        
            text: 'Mortgage CMHC Calculator',
            href: '/tools/MortgageCMHC',
          },
        ]
      },
    ],
  },
  getters: {

    links: (state, getters) => {
      return state.items
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
