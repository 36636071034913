<template>
  <div id="buyerGuide">
    <buyer-guide-component />
  </div>
</template>

<script>
  export default {
    name: 'BuyerGuide',

    components: {
      BuyerGuideComponent: () => import('@/components/guides/BuyerGuide'),
    },
  }
</script>
