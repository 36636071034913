import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/base'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: 'AIzaSyBXIjm4a5k4hOruvMvp1c_i9gW2_JfXR-4',
  authDomain: 'mahsa-real-estate.firebaseapp.com',
  databaseURL: 'https://mahsa-real-estate-default-rtdb.firebaseio.com',
  projectId: 'mahsa-real-estate',
  storageBucket: 'mahsa-real-estate.appspot.com',
  messagingSenderId: '151964918549',
  appId: '1:151964918549:web:ebdc07e557ba5b89133f1c',
}

firebase.initializeApp(firebaseConfig)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
