<template>
  <div id="mortgage">
    <mortgage-calculator />
  </div>
</template>

<script>
  export default {
    name: 'Mortgage',

    components: {
      MortgageCalculator: () => import('@/components/tools/MortgageCalculator'),
    },
  }
</script>
<style scoped>
  #mortgage {
    margin-top: 50px;
  }
  
  @media only screen and (max-width: 600px) {
    #mortgage {
      margin-top: 0px !important;
    }
  }
</style>
