<template>
  <div id="affordability">
    <affordability-calculator />
  </div>
</template>

<script>
  export default {
    name: 'Affordability',

    components: {
      AffordabilityCalculator: () => import('@/components/tools/AffordabilityCalculator'),
    },
  }
</script>
<style scoped>
  #affordability {
    margin-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    #affordability {
      margin-top: 0px !important;
    }
  }
</style>
