<template>
  <div id="buying">
    <buy-sell-banner
      title="Let's Buy You A Home"
      banner="buying_banner.JPG"
    />
    <buying-component />
  </div>
</template>

<script>
  export default {
    name: 'Buying',

    components: {
      BuyingComponent: () => import('@/components/Buying'),
      BuySellBanner: () => import('@/components/home/BuySellBanner'),
    },
  }
</script>
