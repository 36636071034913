<template>
  <div id="landTransferTax">
    <land-transfer-tax-calculator />
  </div>
</template>

<script>
  export default {
    name: 'LandTransferTax',

    components: {
      LandTransferTaxCalculator: () => import('@/components/tools/LandTransferTaxCalculator'),
    },
  }
</script>
<style scoped>
  #landTransferTax {
    margin-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    #landTransferTax {
      margin-top: 0px !important;
    }
  }
</style>
