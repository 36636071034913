import { render, staticRenderFns } from "./LandTransferTax.vue?vue&type=template&id=3c5f5c01&scoped=true"
import script from "./LandTransferTax.vue?vue&type=script&lang=js"
export * from "./LandTransferTax.vue?vue&type=script&lang=js"
import style0 from "./LandTransferTax.vue?vue&type=style&index=0&id=3c5f5c01&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5f5c01",
  null
  
)

export default component.exports